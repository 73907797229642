import React, { useEffect, useState, useLayoutEffect } from "react";
import BlackLoader from "../../images/black-loader.gif";
import { useQuery } from "@apollo/client";
import { renderKatex } from "../../Utils/renderKatex";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LessonXapi } from "./Lesson.xapi";
import { LessonTestOverlay } from "./LessonTest/LessonTestOverlay";
import { LessonTestNavigationBar } from "./LessonTest/LessonTestNavigationBar";
import LessonTestNote from "./LessonTest/LessonTestNote";
import { LessonTestApi } from "./LessonTest/LessonTest.api";
import { LessonTestFunctions } from "./LessonTest/LessonTest.functions";
import { LessonTestGraphql } from "./LessonTest/LessonTest.graphql";
import { LessonTestCounter } from "./LessonTest/LessonTestCounter";
import { LessonTestDeliveredNote } from "./LessonTest/LessonTestDeliveredNote";
import { LessonTestWaitingNote } from "./LessonTest/LessonTestWaitingNote";
import Pusher from "pusher-js";
import {LessonTeacherReviewedNote} from "./LessonCommon/LessonTeacherReviewedNote";
import {LessonTeacherSummaryNote} from "./LessonCommon/LessonTeacherSummaryNote";
import {LessonTeacherInitialNote} from "./LessonCommon/LessonTeacherInitialNote";
import {LessonStudentSummaryNote} from "./LessonCommon/LessonStudentSummaryNote";
import {LessonStudentInitialNote} from "./LessonCommon/LessonStudentInitialNote";

const LessonTest = (props) => {
  let match = { params: useParams() };
  let { i18n } = useTranslation();
  let location = useLocation();
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const [apiInfo, setApiInfo] = useState({});
  const [test, setTest] = useState({});
  const [unit, setUnit] = useState({});
  const [slides, setSlides] = useState([]);
  const [rubrics, setRubrics] = useState([]);
  const [links, setLinks] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [currentSlidePosition, setCurrentSlidePosition] = useState(1);
  const [results, setResults] = useState([]);
  const [startTime, setStartTime] = useState(new Date(Date.now()));
  const [endTime, setEndTime] = useState(null);
  const [deadLine, setDeadLine] = useState(null);
  const [corrections, setCorrections] = useState([]);
  const [finalCorrection, setFinalCorrection] = useState({});

  const [authToken, setAuthToken] = useState(
    searchParams.get("auth_token") ?? ""
  );

  // let possible_types = ['blocked', 'display', 'solve', 'corrected']
  let type = searchParams.get("type") ?? "blocked";
  let devMode = searchParams.get("devMode") ?? false;
  let useTestUser = searchParams.get("useTestUser") ?? false;
  let justFinished = searchParams.get("justFinished") ?? false;

  let pusher_channel = searchParams.get("channel") ?? "activity-notifications";

  const [studentId, setStudentId] = useState(searchParams.get("uuid") ?? null);

  let lessonType = "test";

  const [role, setRole] = useState(
    (searchParams.get("role") ?? "student").toLowerCase()
  );

  const [mode, setMode] = useState(LessonTestFunctions.getMode(null, null));
  const [teacherMode, setTeacherMode] = useState(searchParams.get("teacherMode") === "true" ?? false);
  const [popup, setPopup] = useState("");
  const [statusBanner, setStatusBanner] = useState("");
  const [isSlickCreated, setIsSlickCreated] = useState(false);
  const [xapiStatement, setXapiStatement] = useState({});

  useEffect(() => {
    if (authToken === "" && useTestUser) {
      LessonTestApi.doLogin(globalState);
    } else {
      LessonTestApi.getInitInfo(globalState, authToken);
    }

    return () => {
      // this now gets called when the component unmounts
    };
  }, [authToken]);

  let globalState = {
    id: match.params.id,
    grade_id: match.params.grade_id,
    unit_id: match.params.unit_id,
    props: {
      location: location,
      searchParams: searchParams,
      navigate: navigate,
      i18n: i18n,
    },
    devMode: devMode === "true",
    teacherMode: teacherMode,
    setTeacherMode: setTeacherMode,
    useTestUser: useTestUser === "true",
    justFinished: justFinished === "true",
    studentId: studentId,
    setStudentId: setStudentId,
    lessonType: lessonType,
    type: type,
    channel: pusher_channel,
    role: role,
    setRole: setRole,
    mode: mode,
    setMode: setMode,
    popup: popup,
    setPopup: setPopup,
    statusBanner: statusBanner,
    setStatusBanner: setStatusBanner,
    apiInfo: apiInfo,
    setApiInfo: setApiInfo,
    authToken: authToken,
    setAuthToken: setAuthToken,
    test: test,
    setTest: setTest,
    unit: unit,
    setUnit: setUnit,
    startTime: startTime,
    setStartTime: setStartTime,
    endTime: endTime,
    setEndTime: setEndTime,
    deadLine: deadLine,
    setDeadLine: setDeadLine,
    slides: slides,
    setSlides: setSlides,
    links: links,
    setLinks: setLinks,
    exercises: exercises,
    setExercises: setExercises,
    currentSlidePosition: currentSlidePosition,
    setCurrentSlidePosition: setCurrentSlidePosition,
    results: results,
    setResults: setResults,
    rubrics: rubrics,
    setRubrics: setRubrics,
    corrections: corrections,
    setCorrections: setCorrections,
    finalCorrection: finalCorrection,
    setFinalCorrection: setFinalCorrection,
    isSlickCreated: isSlickCreated,
    setIsSlickCreated: setIsSlickCreated,
    xapiStatement: xapiStatement,
    setXapiStatement: setXapiStatement,
    xapi: LessonXapi.getOrCreateXapiClient(),
    functions: {
      sendTest: (e) => {
        LessonTestFunctions.sendTest(globalState);
      },
      closeTest: (e) => {
        LessonTestFunctions.closeTest(globalState);
      },
      retryExercise: (e) => {
        LessonTestFunctions.retryExercise(globalState);
      },
      resetExercise: (e) => {
        LessonTestApi.resetExercise(globalState);
      },
      saveCorrection: (e) => {
        LessonTestApi.saveCorrection(globalState);
      },
      closeCorrection: (e) => {
        LessonTestApi.closeCorrection(globalState);
      },
      sendCorrection: (e) => {
        LessonTestApi.sendCorrection(globalState);
      },
      goToSlide: (e) => {
        LessonTestFunctions.goToSlide(e, globalState);
      },
      initSlickCarousel: () => {
        LessonTestFunctions.initSlickCarousel(globalState);
      },
      getName: () => {
        return globalState.test.name;
      },
    },
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    let pusher_channel_name = pusher_channel;
    let pusher_event_name = "new-activity-notifications";

    let reverb_app_key = process.env.REACT_APP_REVERB_APP_KEY;
    let reverb_host = process.env.REACT_APP_REVERB_HOST;
    let reverb_port = process.env.REACT_APP_REVERB_PORT;
    let reverb_scheme = process.env.REACT_APP_REVERB_SCHEME;

    const pusher = new Pusher(reverb_app_key, {
      wsHost: reverb_host,
      wsPort: reverb_port || 80,
      wssPort: reverb_port || 443,
      forceTLS: (reverb_scheme || 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
      disableStats: true
    });

    const channel = pusher.subscribe(pusher_channel_name);

    channel.bind(pusher_event_name, function (data) {
      console.log("Pusher - Connection successfully established")
      if (data.message.original.studentsUuids.includes(globalState.studentId)) {
        switch (data.status) {
          case "open":
            LessonTestFunctions.startTest(globalState);
            break;
          case "closed":
            if (mode === "solve_continue" || mode === "solve_first_time")
              LessonTestFunctions.closeTest(globalState);
            break;
        }
      }
    });

    return () => {
      pusher.unsubscribe(pusher_channel_name);
    };
  }, [mode, globalState]);

  useEffect(() => {
    if (
      location &&
      location.pathname &&
      !location.pathname.includes("public/")
    ) {
      let navigateToUrl =
        location.pathname.replace("staging", "public") + location.search;
      navigate(navigateToUrl, { replace: true });
      return "";
    }
  });

  useLayoutEffect(() => {
    renderKatex(document.body);
    localStorage.setItem("unitId", match.params.unit_id);
    globalState.functions.initSlickCarousel();
  });

  useEffect(() => {
    if (mode === "solve_retry" || mode === "display_with_data") {
      LessonTestFunctions.reprocessExercises(globalState);
    }
  }, [mode]);

  useEffect(() => {
    if(globalState.functions.getName()){
      globalState.xapi.sendStatement("experienced", globalState, currentSlidePosition);

      if (globalState.exercises.filter((e) => e.results.answered).length === globalState.exercises.length) {
        globalState.xapi.sendStatement("completed", globalState);
      }
    }
    switch (role) {
      case "student":
        if (
          (mode === "solve_continue" || mode === "solve_first_time") &&
          currentSlidePosition > 0
        ) {
          LessonTestApi.saveExercise(globalState);
        }
        break;
      case "teacher":
        if (mode.includes("in_review") && currentSlidePosition > 0) {
          LessonTestApi.saveCorrection(globalState);
        }
        break;
    }
  }, [currentSlidePosition]);

  useEffect(() => {
    LessonTestFunctions.calculateResults(globalState, exercises);
  }, [exercises]);

  useEffect(()=>{
    LessonXapi.getOrCreateXapiClient().sendStatement(xapiStatement.verb, globalState, xapiStatement.slidePosition);
  }, [xapiStatement]);

  useEffect(()=>{
    if(globalState.functions.getName()){
      globalState.xapi.sendStatement("attempted", globalState);
    }
  }, [test]);

  if (i18n.language !== match.params.locale) {
    i18n.changeLanguage(match.params.locale);
  }

  // $(".sidebar").css("right", -$(".sidebar").width());

  const { loading, error, data } = useQuery(
    LessonTestGraphql.getGradeGraphQLCall(
      globalState.id,
      globalState.unit_id,
      i18n.language
    ),
    {
      client: LessonTestGraphql.getOrCreateGraphQLClient(
        process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "master"
      ),
      errorPolicy: "ignore"
    }
  );

  if (loading)
    return (
      <div align="center" className="pt-5">
        {" "}
        <img src={BlackLoader} alt="Loader" />{" "}
      </div>
    );
  if (error) return <p>Error :(</p>;

  if (mode !== "loading") {
    if (globalState.slides.length === 0)
      LessonTestFunctions.processData(data, globalState);
  }

  LessonTestApi.processJson(globalState);

  function getSlidesToShow(globalState) {
    switch (globalState.mode) {
      case "loading":
      case "blocked":
        return globalState.slides.filter(
          (s) => s.info.slug === "general-information"
        );
      case "display_with_data":
        return globalState.slides.filter((s) => s.info.slug !== "summary");
      case "waiting":
        return globalState.slides.filter((s) => s.info.slug === "waiting");
      case "delivered":
        return globalState.slides.filter((s) => s.info.slug === "delivered");
      case "reviewed":
        return globalState.slides.filter(
          (s) =>
            s.info.slug !== "general-information" && s.info.slug !== "summary"
        );
      default:
        return globalState.slides;
    }
  }

  function getLinksToShow(globalState) {
    switch (globalState.mode) {
      case "loading":
      case "blocked":
        return globalState.links.filter(
          (s) => s.info.slug === "general-information"
        );
      case "display_with_data":
        return globalState.links.filter((s) => s.info.slug !== "summary");
      case "delivered":
        return globalState.links.filter((s) => s.info.slug === "delivered");
      case "reviewed":
        return globalState.links.filter(
          (s) =>
            s.info.slug !== "general-information" && s.info.slug !== "summary"
        );
      default:
        return globalState.links;
    }
  }

  let slidesToShow = getSlidesToShow(globalState);
  let linksToShow = getLinksToShow(globalState);

  return (
    <div className="lesson test exercises">
      <div className="slideNotesContainer">
        <div className="slideNotes">
          {slidesToShow.map((slide, index) => {
            return (
              <div className={`slideNoteContainer`} key={index}>
                {(() => {
                  switch (slide.info.slug) {
                    case "general-information":
                      if (role === "student") {
                        return (
                          <LessonStudentInitialNote
                            key={`slideNote_${slide.slidePosition}`}
                            slide={slide}
                            exercises={globalState.exercises}
                            rubrics={globalState.rubrics}
                            corrections={globalState.corrections}
                            finalCorrection={globalState.finalCorrection}
                            globalState={slide.globalState}
                            slidePosition={slide.slidePosition}
                            deadLine={globalState.deadLine}
                            mode={mode}
                            apiInfo={apiInfo}
                            statusBanner={statusBanner}
                          />
                        );
                      } else {
                        return (
                          <LessonTeacherInitialNote
                            key={`slideNote_${slide.slidePosition}`}
                            slide={slide}
                            exercises={globalState.exercises}
                            rubrics={globalState.rubrics}
                            corrections={globalState.corrections}
                            globalState={slide.globalState}
                            slidePosition={slide.slidePosition}
                            deadLine={globalState.deadLine}
                            mode={mode}
                            apiInfo={apiInfo}
                          />
                        );
                      }
                    case "summary":
                      if (role === "student") {
                        return (
                          <LessonStudentSummaryNote
                            key={`slideNote_${slide.slidePosition}`}
                            slide={slide}
                            exercises={globalState.exercises}
                            globalState={slide.globalState}
                            slidePosition={slide.slidePosition}
                          />
                        );
                      } else {
                        return (
                          <LessonTeacherSummaryNote
                            key={`slideNote_${slide.slidePosition}`}
                            slide={slide}
                            exercises={globalState.exercises}
                            rubrics={globalState.rubrics}
                            corrections={globalState.corrections}
                            finalCorrection={globalState.finalCorrection}
                            globalState={slide.globalState}
                            slidePosition={slide.slidePosition}
                            deadLine={globalState.deadLine}
                            mode={mode}
                            apiInfo={apiInfo}
                          />
                        );
                      }
                    case "delivered":
                      return (
                        <LessonTestDeliveredNote
                          key={`slideNote_${slide.slidePosition}`}
                          slide={slide}
                          globalState={slide.globalState}
                          slidePosition={slide.slidePosition}
                        />
                      );
                    case "waiting":
                      return (
                        <LessonTestWaitingNote
                          key={`slideNote_${slide.slidePosition}`}
                          slide={slide}
                          globalState={slide.globalState}
                          slidePosition={slide.slidePosition}
                        />
                      );
                    case "reviewed":
                      if (role === "student") {
                        return (
                          <LessonStudentInitialNote
                            key={`slideNote_${slide.slidePosition}`}
                            slide={slide}
                            exercises={globalState.exercises}
                            rubrics={globalState.rubrics}
                            corrections={globalState.corrections}
                            finalCorrection={globalState.finalCorrection}
                            globalState={slide.globalState}
                            slidePosition={slide.slidePosition}
                            deadLine={globalState.deadLine}
                            mode={mode}
                            apiInfo={apiInfo}
                            statusBanner={statusBanner}
                          />
                        );
                      } else {
                        return (
                          <LessonTeacherReviewedNote
                            key={`slideNote_${slide.slidePosition}`}
                            slide={slide}
                            exercises={globalState.exercises}
                            rubrics={globalState.rubrics}
                            corrections={globalState.corrections}
                            finalCorrection={globalState.finalCorrection}
                            globalState={slide.globalState}
                            slidePosition={slide.slidePosition}
                            deadLine={globalState.deadLine}
                            mode={mode}
                            apiInfo={apiInfo}
                          />
                        );
                      }
                    default:
                      return (
                        <LessonTestNote
                          key={`slideNote_${slide.slidePosition}`}
                          slide={slide}
                          exerciseInfo={slide.exerciseInfo}
                          corrections={globalState.corrections}
                          globalState={slide.globalState}
                          slidePosition={slide.slidePosition}
                          exercises={globalState.exercises}
                        />
                      );
                  }
                })()}
              </div>
            );
          })}
        </div>
      </div>

      {mode === "delivered" || mode === "waiting" ? (
        <div></div>
      ) : (
        <LessonTestNavigationBar
          globalState={globalState}
          mode={mode}
          currentSlidePosition={currentSlidePosition}
        />
      )}

      <div className="slideNotesTopMenu">
        <a onClick={LessonTestFunctions.toggleFullscreen}
          className="fullscreen">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.19995 4.00001C3.19995 3.78784 3.28424 3.58436 3.43427 3.43433C3.5843 3.2843 3.78778 3.20001 3.99995 3.20001H8.79995C9.01212 3.20001 9.21561 3.2843 9.36564 3.43433C9.51567 3.58436 9.59995 3.78784 9.59995 4.00001C9.59995 4.21219 9.51567 4.41567 9.36564 4.5657C9.21561 4.71573 9.01212 4.80001 8.79995 4.80001H4.79995V8.80001C4.79995 9.01219 4.71567 9.21567 4.56564 9.3657C4.41561 9.51573 4.21212 9.60001 3.99995 9.60001C3.78778 9.60001 3.5843 9.51573 3.43427 9.3657C3.28424 9.21567 3.19995 9.01219 3.19995 8.80001V4.00001ZM14.4 4.00001C14.4 3.78784 14.4842 3.58436 14.6343 3.43433C14.7843 3.2843 14.9878 3.20001 15.2 3.20001H20C20.2121 3.20001 20.4156 3.2843 20.5656 3.43433C20.7157 3.58436 20.8 3.78784 20.8 4.00001V8.80001C20.8 9.01219 20.7157 9.21567 20.5656 9.3657C20.4156 9.51573 20.2121 9.60001 20 9.60001C19.7878 9.60001 19.5843 9.51573 19.4343 9.3657C19.2842 9.21567 19.2 9.01219 19.2 8.80001V4.80001H15.2C14.9878 4.80001 14.7843 4.71573 14.6343 4.5657C14.4842 4.41567 14.4 4.21219 14.4 4.00001ZM3.99995 14.4C4.21212 14.4 4.41561 14.4843 4.56564 14.6343C4.71567 14.7844 4.79995 14.9878 4.79995 15.2V19.2H8.79995C9.01212 19.2 9.21561 19.2843 9.36564 19.4343C9.51567 19.5844 9.59995 19.7878 9.59995 20C9.59995 20.2122 9.51567 20.4157 9.36564 20.5657C9.21561 20.7157 9.01212 20.8 8.79995 20.8H3.99995C3.78778 20.8 3.5843 20.7157 3.43427 20.5657C3.28424 20.4157 3.19995 20.2122 3.19995 20V15.2C3.19995 14.9878 3.28424 14.7844 3.43427 14.6343C3.5843 14.4843 3.78778 14.4 3.99995 14.4ZM20 14.4C20.2121 14.4 20.4156 14.4843 20.5656 14.6343C20.7157 14.7844 20.8 14.9878 20.8 15.2V20C20.8 20.2122 20.7157 20.4157 20.5656 20.5657C20.4156 20.7157 20.2121 20.8 20 20.8H15.2C14.9878 20.8 14.7843 20.7157 14.6343 20.5657C14.4842 20.4157 14.4 20.2122 14.4 20C14.4 19.7878 14.4842 19.5844 14.6343 19.4343C14.7843 19.2843 14.9878 19.2 15.2 19.2H19.2V15.2C19.2 14.9878 19.2842 14.7844 19.4343 14.6343C19.5843 14.4843 19.7878 14.4 20 14.4Z"
              fill="#F9F9FA"
            />
          </svg>
        </a>
      </div>

      {role === "student" && (mode === "solve_first_time" || mode === "solve_continue") ? (
        <LessonTestCounter
          globalState={globalState}
          mode={mode}
          currentSlidePosition={currentSlidePosition}
          startTime={startTime}
          endTime={endTime}
        />
      ) : null}

      {mode === "loading" ? (
          <div className="loading">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
      ) : null}

      <LessonTestOverlay globalState={globalState} mode={mode} popup={popup} />
    </div>
  );
};

export default LessonTest;
